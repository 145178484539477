import "core-js/modules/es.array.push.js";
import { mapState } from 'vuex';

// Services
import addressService from '@/services/crm/addressService.js';
import distributorService from '@/services/statistics/distributorService.js';

// Components
import SiteHeaderComponent from '@/components/core/SiteHeaderComponent.vue';
import TimeFilter from '@/components/statistics/TimeFilter.vue';
export default {
  components: {
    SiteHeaderComponent,
    TimeFilter
  },
  data: () => ({
    address: {
      name: '',
      street: '',
      postcode: '',
      city: '',
      country: '',
      tel: '',
      email: '',
      addressTypes: []
    },
    result: [],
    loading: true,
    search: '',
    sortDesc: false,
    editorContent: '',
    headers: [{
      text: 'Bild',
      align: 'start',
      sortable: false,
      value: 'image_base64'
    }, {
      text: 'Artikel Nr.',
      align: 'start',
      value: 'nr'
    }, {
      text: 'Bezeichnung',
      align: 'start',
      value: 'description'
    }, {
      text: 'Anfangsbestand (Stk.)',
      value: 'openingStock',
      align: 'end'
    }, {
      text: 'Einkäufe (Stk.)',
      value: 'purchases',
      align: 'end'
    }, {
      text: 'Verkäufe (Stk.)',
      value: 'sales',
      align: 'end'
    }, {
      text: 'Inventur (Stk.)',
      value: 'inventory',
      align: 'end'
    }, {
      text: 'Materialverbrauch (Stk.)',
      value: 'materialsInput',
      align: 'end'
    }, {
      text: 'Endbestand (Stk.)',
      value: 'finalStock',
      align: 'end'
    }]
  }),
  props: {
    lfdnr: {
      type: Number,
      required: true
    },
    articleType: {
      type: String,
      required: true
    },
    category: {
      type: Number,
      required: true
    }
  },
  methods: {
    init() {
      addressService.getAddress(this.lfdnr).then(response => {
        this.address = response.data;
      });
      this.loadArticlesStatistics();
    },
    loadArticlesStatistics() {
      this.loading = true;
      distributorService.getArticlesOfCategoryStatistics(this.lfdnr, this.articleType, this.category, this.filterFromDate, this.filterToDate).then(response => {
        this.result = response.data;
        this.loading = false;
      });
    },
    openArticle(item) {
      this.$router.push({
        name: 'statisticsArticle',
        params: {
          lfdnr: this.lfdnr,
          artNr: item.nr
        },
        query: this.$route.query
      });
    }
  },
  computed: {
    ...mapState({
      filterFromDate: state => state.statisticsTimeFilter.fromDate,
      filterToDate: state => state.statisticsTimeFilter.toDate
    })
  },
  mounted() {
    this.init();
  },
  watch: {
    filterFromDate: function () {
      this.loadArticlesStatistics();
    },
    filterToDate: function () {
      this.loadArticlesStatistics();
    }
  }
};